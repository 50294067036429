import React, { useEffect, useState } from "react";

//react-router
import { connect, useSelector } from "react-redux";

import $ from "jquery";
import { Link, useHistory, useLocation } from "react-router-dom";

// dayjs
import dayjs from "dayjs";

//image
import Male from "../../assets/images/male.png";

//inline edit
import EdiText from "react-editext";

//toast
import { Toast } from "../../util/Toast";
import { getDetailsSubAdmin } from '../../store/agency/action';
// import Host from "../table/Host";
import HostList from "../Agency/HostList";

const AgencyDetails = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = queryParams.get('id');

    // eslint-disable-next-line
    //   const [showSendDiamond, setShowSendDiamond] = useState(true);
    //   const [showRecieveDiamond, setshowRecieveDiamond] = useState(false);

    //   const [diamondNewValue, setDiamondNewValue] = useState(0);


    // $(document).ready(function () {
    //   $("img").bind("error", function () {
    //     // Set the default image
    //     $(this).attr("src", `${baseURL}storage/male.png`);
    //   });
    // });

    // document.addEventListener(
    //   "play",
    //   function (e) {
    //     var videos = document.getElementsByTagName("video");
    //     for (var i = 0, len = videos.length; i < len; i++) {
    //       // eslint-disable-next-line
    //       if (videos[i] != e.target) {
    //         videos[i].pause();
    //       }
    //     }
    //   },
    //   true
    // );


    useEffect(() => {
        if (params) {
            props.getDetailsSubAdmin(params);
            //   fetchData(params);
            //   setShowSendDiamond(true);
            //   setshowRecieveDiamond(false);
        }
    }, [params]);

    let now = dayjs();

    //   const handleSendDiamonds = (topSellerId) => {
    //     setShowSendDiamond(true);
    //     setshowRecieveDiamond(false);
    //     fetchData(topSellerId);
    //   };

    //   const handleReceiveDiamonds = (topSellerId) => {
    //     setShowSendDiamond(false);
    //     setshowRecieveDiamond(true);
    //     fetchData(topSellerId);
    //   };

    const fetchData = () => {
        props.getDetailsSubAdmin(params);
    };

    //   const admin = JSON.parse(localStorage.getItem("admin"));
    //   const handleSave = (val, id, type) => {

    //     let data = {
    //       adminId: admin.payload._id,
    //       topSellerId: id,
    //       diamonds: val,
    //     };
    //     props.editCoin(data);
    //     setDiamondNewValue((prevDiamond) => prevDiamond + parseInt(val));
    //   };

    //   const receiveDiamondClasses = `pointer-cursor ${showRecieveDiamond ? 'active' : ''}`;
    //   const sendDiamondClasses = `pointer-cursor ${showSendDiamond ? 'active' : ''}`;

    const { agency } = useSelector((state) => state.agency);
    console.log("agency in details page", agency);
    //   const { topSellerDiamondHistory } = useSelector((state) => state.topSeller);
    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last"></div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/subadmin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/subadmin/agency" className="text-danger">
                                        Agency
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Detail
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="profile-cover"></div>
                    <div class="profile-header ">
                        <div class="profile-img">
                            <img src={agency?.image ? agency?.image : Male} alt="" />
                        </div>
                        <div class="profile-name">
                            <h3>{agency?.name}</h3>
                        </div>
                        <div class="profile-header-menu">
                            <ul class="list-unstyled" id="manageVideoFeed">
                                <li>
                                    <a
                                        href={() => false}
                                        // className={sendDiamondClasses}
                                        at="Feed"
                                    // onClick={() => handleSendDiamonds(topSellerProfile?._id)}
                                    >
                                        {/* Send Diamonds History ({topSellerProfile?.diamondsHistory?.send.length || 0}) */}
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href={() => false}
                                        // className={receiveDiamondClasses}
                                        at="Videos"
                                    // onClick={() => handleReceiveDiamonds(topSellerProfile?._id)}
                                    >
                                        {/* Recieve Diamond History({topSellerProfile?.diamondsHistory?.recieve.length || 0}) */}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">About</h5>
                            {/* <span className="text-danger">{topSeller?.bio ? "Bio : " : ""}</span> */}
                            {/* <span>{topSeller?.bio}</span> */}
                            {/* {topSeller?.bio && <br />} */}
                            {/* {topSeller?.bio && <br />} */}
                            <ul class="list-unstyled profile-about-list">
                                <li>
                                    <i class="fal fa-user m-r-xxs"></i>
                                    <span>
                                        Username &nbsp;
                                        <span className="text-danger">{agency?.name}</span>
                                    </span>
                                </li>
                                {/* <li>
                                    <span className="d-flex" style={{ alignItems: "baseline" }}>
                                        <i class="far fa-gem m-r-xxs"></i>
                                        Diamond &nbsp;
                                        <EdiText
                                            type="text"
                                            //   value={(topSellerProfile?.diamonds) + (diamondNewValue)}
                                            //   onSave={(val) => handleSave(val, topSellerProfile?._id, "diamond")}
                                            className="editClass"
                                        />
                                    </span>
                                </li> */}
                                {/* <li>
                    <span className="d-flex" style={{ alignItems: "baseline" }}>
                      <i class="far fa-registered m-r-xxs "></i>
                      RCoin &nbsp;{" "}
                      <EdiText
                        type="text"
                        value={isCoin ? coin : user?.rCoin}
                        onSave={(val) => handleSave(val, user?._id, "rCoin")}
                        className="editClass"
                      />
                    </span>
                  </li> */}
                                <li>
                                    <i class="fas fa-venus-mars m-r-xxs"></i>
                                    <span>
                                        Email &nbsp;{" "}
                                        <span className="text-danger">{agency?.email}</span>
                                    </span>
                                </li>
                                {/* 
                  <li>
                    <i class="fas fa-child m-r-xxs"></i>
                    <span>
                      Age &nbsp;<span className="text-danger">{user?.age}</span>
                    </span>
                  </li> */}

                                {/* <li>
                    <i class="fas fa-sign-in-alt m-r-xxs"></i>
                    <span>
                      Login Type &nbsp;
                      <span className="text-danger">
                        {user?.loginType === 0
                          ? "Google"
                          : user?.loginType === 1
                            ? "Facebook"
                            : "Quick"}
                      </span>
                    </span>
                  </li>
                  <li>
                    <i class="fas fa-crown m-r-xxs"></i>
                    <span>
                      isVIP &nbsp;
                      <span
                        className={`${user?.isVIP ? "text-success" : "text-primary"
                          }`}
                      >
                        {user?.isVIP ? "Yes" : "No"}
                      </span>
                    </span>
                  </li> */}
                                <li>
                                    <i class="fas fa-map-marker m-r-xxs"></i>
                                    <span>
                                        Hosts &nbsp;<span className="text-danger">{agency?.hosts?.length || 0}</span>
                                    </span>
                                </li>
                                <li>
                                    <i class="fas fa-map-marker m-r-xxs"></i>
                                    <span>
                                        Ip &nbsp;<span className="text-danger">{agency?.ip || `Not Available`}</span>
                                    </span>
                                </li>
                                <li>
                                    <i class="fas fa-clock m-r-xxs"></i>
                                    <span>
                                        Last login date &nbsp;
                                        <span className="text-danger">{agency?.lastLogin}</span>
                                    </span>
                                </li>
                                {/* <li class="profile-about-list-buttons">
                    <button class="btn btn-block btn-primary m-t-md icon_margin">Follow</button>
                    <button class="btn btn-block btn-secondary m-t-md icon_margin">Message</button>
                  </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <div class="card">
            <div class="card-body">
              <h5 class="card-title">Contact Info</h5>
              <ul class="list-unstyled profile-about-list">
                <li>
                  <i class="far fa-envelope m-r-xxs"></i>
                  <span>{topSellerProfile?.email}</span>
                </li>
                <li>
                  <i class="far fa-compass m-r-xxs"></i>
                  <span>
                    Lives in <span>{topSellerProfile?.country}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div> */}
                </div>
                <div class="col-md-12 col-lg-8">
                    <div class="card card-bg">
                        <div class="card-body ">
                            {agency && agency?.hosts?.length > 0 &&
                                // <span>agency hosts</span>
                                <HostList user={agency.hosts} />
                                // <TopSellerRecieveDiamond data={topSellerDiamondHistory.topSellerDiamondHistory.recieve} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    //   getPost,
    //   getLike,
    //   getComment,
    //   getFollowersFollowing,
    //   getVideo,
    //   deleteComment,
    //   editCoin,
    //   getTopSellerProfile,
    //   topSellerDiamondHistoryComplete,
    getDetailsSubAdmin
})(AgencyDetails);
