export const GET_SUBADMIN = "GET_SUBADMIN";
export const GET_ALL_SUBADMIN_LIST = "GET_ALL_SUBADMIN_LIST";
export const GET_ALL_HOSTS_SUBADMIN = "GET_ALL_HOSTS_SUBADMIN";

export const REGISTER_SUBADMIN = "REGISTER_SUBADMIN";
export const REGISTER_SUBADMIN_FAILURE = "REGISTER_SUBADMIN_FAILURE";
export const BLOCK_UNBLOCK_SWITCH_SUBADMIN = "BLOCK_UNBLOCK_SWITCH_SUBADMIN";
export const ACTIVE_AGENCY_SUBADMIN = "ACTIVE_AGENCY_SUBADMIN";
export const REJECT_AGENCY_SUBADMIN = "REJECT_AGENCY_SUBADMIN";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";
export const SET_SUBADMIN = "SET_SUBADMIN";
export const UNSET_SUBADMIN = "UNSET_SUBADMIN";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
