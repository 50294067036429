import React, { useState } from "react";

//routing
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";

//action
import { register } from "../../store/sub-admin/action";
import { WebAppName } from "../../util/Config";

const SubAdminSignUp = (props) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    userName: "",
    email: "",
    password: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for any empty fields
    if (!email || !password || !userName) {
      const newErrors = {};

      if (!userName) newErrors.userName = "Name is Required!";
      if (!email) newErrors.email = "Email is Required!";
      if (!password) newErrors.password = "Password is Required!";

      setError({ ...error, ...newErrors });
      return; // Prevent submission when fields are empty
    }

    // Clear any previous errors if everything is filled
    setError({ userName: "", email: "", password: "" });

    // Prepare data for registration
    const data = {
      name: userName,
      email,
      password,
    };

    // Dispatch registration action
    props.register(data);
  };

  return (
    <>
      <div className="login-page back__style">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-5">
              <div class="card login-box-container">
                <div class="card-body">
                  <div class="authent-logo mb-4">
                    <span class="text-danger h1">{WebAppName}</span>
                  </div>
                  <div class="authent-text">
                    {/* <p>Welcome to Bigee Live</p> */}
                    <p>Register a new account</p>
                  </div>

                  <form autoComplete="off">
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@123"
                          required
                          value={userName}
                          onChange={(e) => {
                            setUserName(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                userName: "User Name is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                userName: "",
                              });
                            }
                          }}
                        />
                        <label for="floatingInput">Name</label>
                      </div>
                      <div class="mt-2 ml-2 mb-3">
                        {error.userName && (
                          <div class="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.userName}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          required
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                email: "Email is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                email: "",
                              });
                            }
                          }}
                        />
                        <label for="floatingInput">Email address</label>
                      </div>
                      <div class="mt-2 ml-2 mb-3">
                        {error.email && (
                          <div class="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                password: "Password is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                password: "",
                              });
                            }
                          }}
                        />
                        <label for="floatingPassword">Password</label>
                      </div>
                      <div class="mt-2 ml-2 mb-3">
                        {error.password && (
                          <div class="pl-1 text-left pb-1">
                            <span className="text-red">{error.password}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-danger m-b-xs"
                        onClick={handleSubmit}
                      >
                        Sign Up
                      </button>
                    </div>
                  </form>
                  <div class="flex justify-between w-full">
                    Already have an account?
                    <Link to="/" class="text-info">
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { register })(SubAdminSignUp);
