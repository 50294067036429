import React, { useEffect, useState } from "react";

//redux
import { connect, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { getAllAgencyHosts } from "../../store/agency/action";
import HostList from "../../component/Agency/HostList";

function AgencyHostsTableByStatus(props) {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const match = useRouteMatch();
  // const history = useHistory();
  const status = match.params.status.split(":")[1];
  const { hosts } = useSelector((state) => state.agency);

  useEffect(() => {
    props.getAllAgencyHosts(activePage, rowsPerPage, search, sDate, eDate);
  }, [status, activePage, rowsPerPage, search, sDate, eDate]);

  const statusHosts = hosts?.filter(
    (host) => host.hostRequest.status === status
  );
  useEffect(() => {
    setData(hosts);
  }, [hosts]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(hosts);
  }, [date, hosts]);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Host {status}
            </h3>
          </div>
        </div>
      </div>
      <HostList
        user={statusHosts}
        sDate={sDate}
        eDate={eDate}
        setActivePage={setActivePage}
        setSearch={setSearch}
        date={date}
        activePage={activePage}
        rowsPerPage={rowsPerPage}
        setDate={setDate}
        setsDate={setsDate}
        seteDate={seteDate}
        setRowsPerPage={setRowsPerPage}
        getAllAgencyHosts={getAllAgencyHosts}
      />
    </>
  );
}

export default connect(null, { getAllAgencyHosts })(AgencyHostsTableByStatus);
