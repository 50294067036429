import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { register } from "../../store/agency/action";
import { getAllSubAdminsUniqueIds } from "../../store/sub-admin/action";
import { WebAppName } from "../../util/Config";

const AgencySignUpPage = (props) => {
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminId, setAdminId] = useState("");
  const [adminIdURL, setAdminIdURL] = useState("");
  const [error, setError] = useState({
    userName: "",
    email: "",
    password: "",
    adminId: "",
  });

  const { subadmin } = useSelector((state) => state.subAdmin);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("admin")) {
      const adminId = queryParams.get("admin");
      setAdminIdURL(adminId);
    } else {
      setAdminId(""); // Reset adminId if not present in URL
      props.getAllSubAdminsUniqueIds();
    }
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for any empty fields
    if (!email || !password || !userName || (!adminId && !adminIdURL)) {
      const newErrors = {};
      console.log("handlsubmit signup agency", adminIdURL)
      if (!userName) newErrors.userName = "Name is Required!";
      if (!email) newErrors.email = "Email is Required!";
      if (!password) newErrors.password = "Password is Required!";
      if (!adminId && !adminIdURL) newErrors.adminId = "Admin ID is Required!";

      setError({ ...error, ...newErrors });
      return; // Prevent submission when fields are empty
    }
    // Clear any previous errors if everything is filled
    setError({ userName: "", email: "", password: "", adminId: "" });
    // Prepare data for registration
    const data = {
      name: userName,
      email,
      password,
      adminId: adminIdURL ? adminIdURL : adminId,
    };
    // Dispatch registration action
    props.register(data);
  };

  return (
    <>
      <div className="login-page back__style">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 col-lg-5">
              <div className="card login-box-container">
                <div className="card-body">
                  <div className="authent-logo mb-4">
                    <span className="text-danger h1">{WebAppName}</span>
                  </div>
                  <div className="authent-text">
                    <p>Register a new account</p>
                  </div>

                  <form autoComplete="off">
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@123"
                          required
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Username</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.userName && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.userName}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.email && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.password && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red">
                              {error.password}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {!adminIdURL && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <select
                            className="form-control"
                            id="floatingPassword"
                            value={adminId}
                            onChange={(e) => setAdminId(e.target.value)}
                          >
                            <option value="">
                              Select Admin
                            </option>
                            {subadmin ? (
                              subadmin.map((item) => (
                                <option
                                  key={item.serialId}
                                  value={item.serialId}
                                >
                                  {item.name}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                          <label htmlFor="floatingPassword">
                            Select Admin
                          </label>
                        </div>
                        <div className="mt-2 ml-2 mb-3">
                          {error.adminId && (
                            <div className="pl-1 text-left pb-1">
                              <span className="text-red">
                                {error.adminId}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-danger m-b-xs"
                        onClick={handleSubmit}
                      >
                        Sign Up
                      </button>
                    </div>
                  </form>
                  <div className="flex justify-between w-full">
                    Already have an account?
                    <Link to="/" className="text-info">
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  register,
  getAllSubAdminsUniqueIds,
})(AgencySignUpPage);
