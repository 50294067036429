import React, { useEffect, useState } from "react";

//jquery
import $ from "jquery";

//dayjs
import dayjs from "dayjs";

//redux
import { connect, useSelector } from "react-redux";

//action
import { topSellerDiamondHistoryComplete } from "../../store/top-seller/action";

//pagination
import Pagination from "../../pages/Pagination";

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//routing
import { Link } from "react-router-dom";

import TopSellerRecieveDiamond from '../table/history/TopSellerRecieveDiamond';

const TopSellerDiamondRecieveHistory = (props) => {
    const topSeller = JSON.parse(localStorage.getItem("topSeller"));
    useEffect(() => {
        props.topSellerDiamondHistoryComplete(topSeller._id);
    }, [])

    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const [date, setDate] = useState([]);
    const [sDate, setsDate] = useState("ALL");
    const [eDate, seteDate] = useState("ALL");
    const { topSellerDiamondHistory } = useSelector((state) => state.topSeller);
    console.log("diamondsHistoryTopSeller", topSellerDiamondHistory);
    const diamondHistory = topSellerDiamondHistory?.topSellerDiamondHistory?.recieve;
    const maxDate = new Date();
    useEffect(() => {
        $("#card").click(() => {
            $("#datePicker").removeClass("show");
        });
    }, []);

    // useEffect(() => {
    //   props.vipPlanHistory(null, activePage, rowsPerPage, sDate, eDate); // eslint-disable-next-line
    // }, [activePage, rowsPerPage, sDate, eDate]);

    useEffect(() => {
        setData(diamondHistory);
    }, [diamondHistory]);

    useEffect(() => {
        if (date.length === 0) {
            setDate([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                },
            ]);
        }
        $("#datePicker").removeClass("show");
        setData(diamondHistory);
    }, [date, diamondHistory]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };
    const getAllHistory = () => {
        setActivePage(1);
        setsDate("ALL");
        seteDate("ALL");
        $("#datePicker").removeClass("show");
        props.topSellerDiamondHistoryComplete(topSeller._id);
    };


    const collapsedDatePicker = () => {
        $("#datePicker").toggleClass("collapse");
    };

    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-white ">Top Seller Recieve Diamond History</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Diamond
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                                    <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                                        <button
                                            className="btn btn-info"
                                            style={{ marginRight: 5 }}
                                            onClick={getAllHistory}
                                        >
                                            All
                                        </button>
                                        <button
                                            className="collapsed btn btn-info ml-5"
                                            value="check"
                                            data-toggle="collapse"
                                            data-target="#datePicker"
                                            onClick={collapsedDatePicker}
                                        >
                                            Analytics
                                            <ExpandMoreIcon />
                                        </button>
                                        <p style={{ paddingLeft: 10 }} className="my-2 ">
                                            {sDate !== "ALL" && sDate + " to " + eDate}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0"></div>
                                <div
                                    id="datePicker"
                                    className="collapse mt-5 pt-5"
                                    aria-expanded="false"
                                >
                                    <div className="container table-responsive">
                                        <div key={JSON.stringify(date)}>
                                            <DateRangePicker
                                                maxDate={maxDate}
                                                onChange={(item) => {
                                                    setDate([item.selection]);
                                                    const dayStart = dayjs(
                                                        item.selection.startDate
                                                    ).format("M/DD/YYYY");
                                                    const dayEnd = dayjs(item.selection.endDate).format(
                                                        "M/DD/YYYY"
                                                    );
                                                    setActivePage(1);
                                                    setsDate(dayStart);
                                                    seteDate(dayEnd);
                                                    props.vipPlanHistory(
                                                        null,
                                                        activePage,
                                                        rowsPerPage,
                                                        sDate,
                                                        eDate
                                                    );
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                ranges={date}
                                                direction="horizontal"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body card-overflow pt-0">
                            <TopSellerRecieveDiamond data={data} />
                            <Pagination
                                activePage={activePage}
                                rowsPerPage={rowsPerPage}
                                userTotal={data?.length}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { TopSellerRecieveDiamond, topSellerDiamondHistoryComplete })(TopSellerDiamondRecieveHistory);
