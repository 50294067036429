import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Topnav from "../component/navbar/Topnav";

//loader
import Spinner from "./Spinner";
import TopUpResellerNavbar from "../component/navbar/TopupResellerNavbar.js";
import TopupResellerDashboard from "../component/TopupReseller/TopupResellerDashboard.js";
import SubTopNav from "../component/navbar/SubTopNav.js";
import TopSellerSignUp from "../component/TopupReseller/SignUpPage";
import TopSellerLoginPage from "../component/TopupReseller/TopSellerLogin";
import Profile from "../component/TopupReseller/Profile";
import TopSellerTopNav from "../component/TopupReseller/TopSellerTopNav.js";
import TopSellerSendDiamondHistory from "../component/TopupReseller/TopSellerSendDiamondHistory.js";
import TopSellerRecieveDiamondHistory from '../component/TopupReseller/TopSellerRecieveDiamondHistory.js';
const TopUpReseller = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/top-seller") {
      history.push("/top-seller/dashboard");
    } // eslint-disable-next-line
  }, []);
  const token = localStorage.getItem("TOKEN");

  return (
    <>
      {token && (
        <div class="page-container">
          <TopUpResellerNavbar />
          <div class="page-content">
            <TopSellerTopNav />
            <div class="main-wrapper">
              <Switch>
                <Route
                  path={`${location.path}/dashboard`}
                  exact
                  component={TopupResellerDashboard}
                />
                <Route
                  path={`${location.path}/profile`}
                  exact
                  component={Profile}
                />
                <Route
                  path={`${location.path}/history/send-diamonds`}
                  exact
                  component={TopSellerSendDiamondHistory}
                />
                <Route
                  path={`${location.path}/history/recieve-diamonds`}
                  exact
                  component={TopSellerRecieveDiamondHistory}
                />
              </Switch>
              <Spinner />
            </div>
          </div>
        </div>
      )}
      <div>
        <Switch>
          <Route
            exact
            path={`${location.path}/signup`}
            component={TopSellerSignUp}
          />
          <Route
            exact
            path={`${location.path}/login`}
            component={TopSellerLoginPage}
          />
        </Switch>
      </div>
    </>
  );
};

export default TopUpReseller;
