import {
  BLOCK_UNBLOCK_SWITCH_TOP_SELLER,
  GET_TOP_SELLER,
  GET_HISTORY,
  REGISTER_TOP_SELLER,
  REGISTER_TOP_SELLER_FAILURE,
  SET_TOP_SELLER,
  UNSET_TOP_SELLER,
  UPDATE_PROFILE,
  GET_TOP_SELLER_PROFILE,
  EDIT_COIN_TOP_SELLER,
  GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY,
  GET_TOP_SELLER_RECIEVED_DIAMOND_HISTORY,
  GET_TOP_SELLER_DIAMOND_HISTORY,
} from "./types";
import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";
const initialState = {
  isAuth: false,

  topSeller: [],
  totalTopSeller: 0,
  activeSeller: 0,
  history: [],
  totalHistorySeller: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
  topSellerProfile: {},
};

const topSellerReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case SET_TOP_SELLER:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      return {
        ...state,
        isAuth: true,
        topSeller: decoded,
      };
    case REGISTER_TOP_SELLER:
      return {
        ...state,
        topSeller: action.payload.topSeller,
      };

    case GET_TOP_SELLER_PROFILE:
      return {
        ...state,
        topSellerProfile: action.payload,
      };

    case REGISTER_TOP_SELLER_FAILURE:
      return state;
    case GET_TOP_SELLER:
      return {
        ...state,
        topSeller: action.payload.topSeller,

        totalTopSeller: action.payload.total,
      };

    case BLOCK_UNBLOCK_SWITCH_TOP_SELLER:
      return {
        ...state,
        topSeller: state?.topSeller?.map((topSeller) => {
          if (topSeller._id === action.payload._id)
            return {
              ...topSeller,
              isBlock: action.payload.isBlock,
            };
          else return topSeller;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN_TOP_SELLER:
      return {
        ...state,
        topSeller: state.topSeller.map((user) => {
          console.log("user in state", user);
          if (user._id === action.payload.id) return action.payload.data;
          else return topSeller;
        }),
      };
    case UNSET_TOP_SELLER:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("admin");
      localStorage.removeItem("KEY");

      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        topSeller: {},
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        topSeller: {
          ...state.topSeller,
          _id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.image,
          flag: action.payload.flag,
        },
      };
    case GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY:
      return {
        ...state,
        diamondsHistoryTopSeller: action.payload,
      };
    case GET_TOP_SELLER_DIAMOND_HISTORY:
      return {
        ...state,
        topSellerDiamondHistory: action.payload,
      };
    default:
      return state;
  }
};

export default topSellerReducer;
