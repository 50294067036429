import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Topnav from "../component/navbar/Topnav";

//loader
import Spinner from "./Spinner";
import Profile from "../component/SubAdmin/Profile";
import SubAdminNavbar from "../component/navbar/SubAdminNavbar";
import SubAdminDashboard from "../component/SubAdmin/SubAdminDashboard";
import SubAdminWithDrawalTable from "../component/SubAdmin/SubAdminWithDrawalTable";
import SubAdminAgencyTable from "../component/SubAdmin/SubAdminAgencyTable.js";
import SubAdminLoginPage from "../component/SubAdmin/SubAdminLoginPage.js";
import SubAdminSignUp from "../component/SubAdmin/SubAdminSignUp.js";
import SubAdminTopNav from "../component/SubAdmin/SubAdminTopNav.js";
import agencyDetails from "../component/SubAdmin/agencyDetails.js";
import ActiveAgenciesList from "../component/SubAdmin/ActiveAgenciesList.js";
import PendingAgenciesList from "../component/SubAdmin/PendingAgenciesList.js";
import RejectedAgenciesList from "../component/SubAdmin/RejectedAgenciesList.js";
import HostsSubAdmin from "../component/SubAdmin/HostsSubAdmin.js";


const SubAdmin = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/subadmin") {
      history.push("/subadmin/dashboard");
    } // eslint-disable-next-line
  }, []);
  const token = localStorage.getItem("TOKEN");

  return (
    <>
      <div>
        <Switch>
          <Route
            exact
            path={`${location.path}/login`}
            component={SubAdminLoginPage}
          />
          <Route
            exact
            path={`${location.path}/signup`}
            component={SubAdminSignUp}
          />
        </Switch>
      </div>
      {token && (
        <div class="page-container">
          <SubAdminNavbar />
          <div class="page-content">
            <SubAdminTopNav />
            <div class="main-wrapper">
              <Switch>
                <Route
                  path={`${location.path}/dashboard`}
                  exact
                  component={SubAdminDashboard}
                />
                <Route
                  path={`${location.path}/profile`}
                  exact
                  component={Profile}
                />
                <Route
                  path={`${location.path}/agency`}
                  exact
                  component={SubAdminAgencyTable}
                />
                <Route
                  path={`${location.path}/hosts`}
                  exact
                  component={HostsSubAdmin}
                />
                <Route
                  path={`${location.path}/agency/detail`}
                  exact
                  component={agencyDetails}
                />
                <Route
                  path={`${location.path}/reject/agency`}
                  exact
                  component={RejectedAgenciesList}
                />
                <Route
                  path={`${location.path}/pending/agency`}
                  exact
                  component={PendingAgenciesList}
                />
                <Route
                  path={`${location.path}/active/agency`}
                  exact
                  component={ActiveAgenciesList}
                />
                <Route
                  path={`${location.path}/withdraw`}
                  exact
                  component={SubAdminWithDrawalTable}
                />
              </Switch>
              <Spinner />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubAdmin;
