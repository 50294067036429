import React, { useEffect, useState } from "react";

//redux
import { connect, useSelector } from "react-redux";

//action
import { getProfile } from "../../store/masterAgency/action";
import { useRouteMatch } from "react-router-dom";
//pagination
import Pagination from "../../pages/Pagination";

//routing
import { Link } from "react-router-dom";

import DiamondHistoryTable from "./DiamondHistoryTable";

const MasterDiamondHistory = (props) => {
  const match = useRouteMatch();
  const status = match.params.status;

  useEffect(() => {
    props.getProfile();
  }, [status]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(parseInt(value, 10));
  };

  const { masterAgency } = useSelector(
    (state) => state?.masterAgency?.masterAgency
  );

  const diamondStatusHistory = masterAgency?.diamondsHistory.filter(
    (history) => history.status === status
  );
  const indexOfLastItem = activePage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = diamondStatusHistory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white ">{`${
              status.charAt(0).toUpperCase() + status.slice(1)
            } Diamond History`}</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Diamond
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      // onClick={getAllHistory}
                    >
                      All
                    </button>
                    {/* <button
                                            className="collapsed btn btn-info ml-5"
                                            value="check"
                                            data-toggle="collapse"
                                            data-target="#datePicker"
                                            onClick={collapsedDatePicker}
                                        >
                                            Analytics
                                            <ExpandMoreIcon />
                                        </button>
                                        <p style={{ paddingLeft: 10 }} className="my-2 ">
                                            {sDate !== "ALL" && sDate + " to " + eDate}
                                        </p> */}
                  </div>
                </div>
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0"></div> */}
                {/* <div
                                    id="datePicker"
                                    className="collapse mt-5 pt-5"
                                    aria-expanded="false"
                                >
                                    <div className="container table-responsive">
                                        <div key={JSON.stringify(date)}>
                                            <DateRangePicker
                                                maxDate={maxDate}
                                                onChange={(item) => {
                                                    setDate([item.selection]);
                                                    const dayStart = dayjs(
                                                        item.selection.startDate
                                                    ).format("M/DD/YYYY");
                                                    const dayEnd = dayjs(item.selection.endDate).format(
                                                        "M/DD/YYYY"
                                                    );
                                                    setActivePage(1);
                                                    setsDate(dayStart);
                                                    seteDate(dayEnd);
                                                    props.vipPlanHistory(
                                                        null,
                                                        activePage,
                                                        rowsPerPage,
                                                        sDate,
                                                        eDate
                                                    );
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                ranges={date}
                                                direction="horizontal"
                                            />
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <DiamondHistoryTable
                data={currentItems}
                activePage={activePage}
                rowsPerPage={rowsPerPage}
              />
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={diamondStatusHistory?.length}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getProfile })(MasterDiamondHistory);
