import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component

import AgencyDashboard from "../component/Agency/AgencyDashboard.js";

import AgencyHostsTable from "../component/table/AgencyHostsTable.js";
//loader
import Spinner from "./Spinner";
import Profile from "../component/Agency/Profile.js";
import AgencyNavbar from "../component/navbar/AgencyNavbar.js";
// import AgencyWithDrawalTable from "../component/table/AgencyWithDrawalTable.js";
import AgencyLoginPage from "../component/Agency/AgencyLogin.js";
import AgencySignUpPage from "../component/Agency/AgencySignUpPage.js";
import AgencyTopNav from "../component/Agency/AgencyTopNav.js";
import AgencyHostsTableByStatus from "../component/table/AgencyHostsTableByStatus.js";
import CoinManage from "../component/Agency/CoinManage.js";
import DiamondHistory from "../component/Agency/DiamondHistory.js";

const Agency = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/agency") {
      history.push("/agency/dashboard");
    } // eslint-disable-next-line
  }, []);
  const token = localStorage.getItem("TOKEN");

  const { agency } = useSelector((state) => state?.agency?.agency);

  return (
    <>
      <div class="page-container">
        <AgencyNavbar />
        <div class="page-content">
          <AgencyTopNav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={AgencyDashboard}
              />
              <Route
                path={`${location.path}/profile`}
                exact
                component={Profile}
              />
              {agency?.isTopUpAgency && (
                <Route
                  path={`${location.path}/history/:status`}
                  exact
                  component={DiamondHistory}
                />
              )}
              {agency?.isHostAgency && (
                <Route
                  path={`${location.path}/hosts/:status`}
                  exact
                  component={AgencyHostsTableByStatus}
                />
              )}
              {agency?.isHostAgency && (
                <Route
                  path={`${location.path}/hosts`}
                  exact
                  component={AgencyHostsTable}
                />
              )}
              {agency?.isTopUpAgency && (
                <Route
                  path={`${location.path}/coins`}
                  exact
                  component={CoinManage}
                />
              )}
              {/* <Route
                  path={`${location.path}/withdraw`}
                  exact
                  component={AgencyWithDrawalTable}
                /> */}
            </Switch>
            <Spinner />
          </div>
        </div>
      </div>

      {/* <div>
        <Switch>
          <Route
            exact
            path={`${location.path}/login`}
            component={AgencyLoginPage}
          />
          <Route
            exact
            path={`${location.path}/signup`}
            component={AgencySignUpPage}
          />
        </Switch>
      </div> */}
    </>
  );
};

export default Agency;
