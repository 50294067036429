import {
  BLOCK_UNBLOCK_SWITCH_AGENCY,
  GET_AGENCY,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_AGENCY,
  REGISTER_AGENCY_FAILURE,
  SET_AGENCY,
  UNSET_AGENCY,
  UPDATE_PROFILE,
  GET_AGENCY_DETAILS,
  GET_ALL_AGENCY_HOSTS,
  ACTIVE_HOST_AGENCY,
  REJECT_HOST_AGENCY,
} from "./types";
import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";
import { act } from "react";
const initialState = {
  isAgency: false,
  agency: [],
  totalAgency: 0,
  activeAgency: 0,
  history: [],
  totalHistoryAgency: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
};

const agencyReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case REGISTER_AGENCY:
      return {
        ...state,
        agency: action.payload.agency,
      };

    case REGISTER_AGENCY_FAILURE:
      return state;
    case GET_AGENCY:
      return {
        ...state,
        agency: action.payload.agency,

        totalAgency: action.payload.total,
      };

    case BLOCK_UNBLOCK_SWITCH_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency._id === action.agency._id)
            return {
              ...agency,
              isBlock: action.payload.isBlock,
            };
          else return agency;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload.id) return action.payload.data;
          else return user;
        }),
      };
    case SET_AGENCY:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      return {
        ...state,
        isAgency: true,
        agency: decoded,
      };

    case UNSET_AGENCY:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("admin");
      localStorage.removeItem("KEY");

      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAgency: false,
        agency: {},
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        agency: {
          ...state.agency,
          _id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.image,
          flag: action.payload.flag,
          agency: action.payload,
          total: action.payload.total,
        },
      };
    case GET_AGENCY_DETAILS:
      return {
        ...state,
        agency: action.payload,
      };
    case GET_ALL_AGENCY_HOSTS:
      return {
        ...state,
        hosts: action.payload,
      };
    case ACTIVE_HOST_AGENCY:
      return {
        ...state,
        ...action.payload,
      };
    case REJECT_HOST_AGENCY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default agencyReducer;
