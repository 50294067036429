import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import { permissionError } from "../../util/Alert";
// import { Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import Pagination from "../../pages/Pagination";
// import dayjs from "dayjs";
import Male from "../../assets/images/male.png";
import { activeHost, rejectHost } from "../../store/agency/action";
import dayjs from "dayjs";

import { DateRangePicker } from "react-date-range";

const HostTable = (props) => {
  const {
    user,
    sDate,
    date,
    activePage,
    rowsPerPage,
    eDate,
    setActivePage,
    setSearch,
    setDate,
    seteDate,
    setsDate,
    setRowsPerPage,
    getAllAgencyHosts,
  } = props;
  console.log("user,,,,,,,,,,,,,,,,,,,,,,,,,", user);
  // // const history = useHistory();
  // const [activePage, setActivePage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // // const [search, setSearch] = useState("ALL");
  // const [sDate, setsDate] = useState("ALL");
  // const [eDate, seteDate] = useState("ALL");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };
  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  // const handleUserInfo = (user) => {
  //   localStorage.setItem("user", JSON.stringify(user));
  //   history.push("/admin/user/detail");
  // };

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
  };

  return (
    <>
      <div className="page-title">
        {/* <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Host
            </h3>
          </div>
        </div> */}
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllUser}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      user-toggle="collapse"
                      user-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY/MM/DD");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "YYYY/MM/DD"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                          props.getAllAgencyHosts(
                            null,
                            activePage,
                            rowsPerPage,
                            sDate,
                            eDate
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Unique ID</th>
                    <th>Image</th>
                    <th>Username</th>
                    <th>Gender</th>
                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>RCoin</th>
                    <th>Country</th>
                    <th>Active</th>
                    <th>Reject</th>
                    <th>TotalDays</th>
                    <th>TotalHrs</th>
                    <th>CoinTarget</th>
                    <th>CoinShare</th>
                    <th>BasicSalary</th>
                    <th>TotalSalary</th>
                    {/* <th>Level</th> */}
                    {/* <th>Follower</th> */}
                    {/* <th
                      onClick={handleFollowerSort}
                      style={{ cursor: "pointer" }}
                    >
                      Follower {followerSort ? " ▼" : " ▲"}
                    </th> */}
                    {/* <th>Following</th> */}
                    {/* <th
                      onClick={handleFollowingSort}
                      style={{ cursor: "pointer" }}
                    >
                      Following {followingSort ? " ▼" : " ▲"}
                    </th> */}
                    {/* <th>isBlock</th> */}
                    {/* <th>Info</th> */}
                    {/* <th>History</th> */}
                  </tr>
                </thead>
                <tbody>
                  {user?.length > 0 ? (
                    user?.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <th>{user?.uniqueId}</th>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={user?.image ? user?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{user?.username}</td>
                          <td>{user?.gender}</td>
                          <td className="text-danger">{user?.rCoin}</td>
                          <td className="text-success">{user?.country}</td>
                          {/* <td className="text-warning">{user.level.name}</td> */}
                          <td>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() => props.activeHost(user._id)}
                            >
                              Active
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => props.rejectHost(user._id)}
                            >
                              Reject
                            </button>
                          </td>
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={user.isBlock}
                                onChange={() =>
                                  handleBlockUnblockSwitch_(user._id)
                                }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      user.isBlock ? "5px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {user.isBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td> */}
                          {/* <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleUserInfo(user)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={user?.length}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { activeHost, rejectHost })(HostTable);