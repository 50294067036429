/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Chart as ChartJS } from "chart.js/auto";
import { Line, Doughnut } from "react-chartjs-2";
import { getProfile } from "../../store/sub-admin/action";
//dayjs
import dayjs from "dayjs";

//datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

// action
import { getDashboard, getAnalytic } from "../../store/dashboard/action";
import { getSetting } from "../../store/setting/action";
import { connect, useSelector } from "react-redux";
import { Toast } from "../../util/Toast";
import { Button, IconButton, Typography } from "@material-ui/core";
import { frontendUrl } from "../../util/Config";
const SubAdminDashboard = (props) => {
  useEffect(() => {
    props.getProfile();
  }, []);

  let label = [];
  let data = [];
  let data1 = [];
  const [type, setType] = useState("USER");

  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const analytic = useSelector((state) => state.dashboard.analytic);
  const setting = useSelector((state) => state.setting.setting);
  const { subAdmin } = useSelector((state) => state.subAdmin.subAdmin);
  console.log("subAdmin state in dashboard", subAdmin);

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const startDate = dayjs(firstDay).format("M/DD/YYYY");
  const endDate = dayjs(lastDay).format("M/DD/YYYY");
  const [sDate, setSDate] = useState(startDate);
  const [eDate, setEDate] = useState(endDate);
  const [analyticData, setAnalyticData] = useState([]);

  useEffect(() => {
    props.getDashboard();
    props.getSetting();
    props.getAnalytic("USER", sDate, eDate); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAnalyticData(analytic);
  }, [analytic]);

  if (analyticData.length > 0) {
    if (type === "REVENUE" && analyticData.length === 1) {
      //eslint-disable-next-line
      analyticData[0]?.coinRevenue?.map((date) => {
        label.push(date._id);
      });
      //eslint-disable-next-line
      analyticData[0]?.vipRevenue?.map((date) => {
        label.push(date._id);
      });
      const uniqLabel = [...new Set(label)];
      //eslint-disable-next-line
      uniqLabel.map((data__) => {
        const count = analyticData[0]?.coinRevenue?.find(
          (element) => element._id === data__
        );
        if (count) {
          data.push(setting.currency === "$" ? count.dollar : count.rupee);
        } else {
          data.push(0);
        }
        const count_ = analyticData[0]?.vipRevenue?.find(
          (element) => element._id === data__
        );
        if (count_) {
          data1.push(setting.currency === "$" ? count_.dollar : count_.rupee);
        } else {
          data1.push(0);
        }
      });
    } else {
      //eslint-disable-next-line
      analyticData.map((data_) => {
        label.push(data_._id);
        data.push(data_.count);
      });
    }
  }

  const handleAnalytic = (type) => {
    setSDate(startDate);
    setEDate(endDate);
    setType(type);
    if (type !== "ACTIVE USER") {
      props.getAnalytic(type, startDate, endDate);
    }
  };

  const chartData = {
    labels: label,
    datasets: [
      {
        label: type,
        data: data,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgb(255, 99, 132)",
        lineTension: 0.5,
      },
    ],
  };
  const multiLineChartData = {
    labels: label,
    datasets: [
      {
        label: "Coin Revenue",
        data: data,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgb(255, 99, 132)",
        lineTension: 0.5,
      },
      {
        label: "VIP Revenue",
        data: data1,
        fill: true,
        backgroundColor: "rgb(205,235,255,0.1)",
        borderColor: "#68B9F0",
        lineTension: 0.5,
      },
    ],
  };

  const pieChartData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Total User", "Active User"],
    datasets: [
      {
        data: [dashboard.totalUser, dashboard.activeUser],
        backgroundColor: ["#E8538F", "#FC9494"],
        hoverBackgroundColor: ["#E8538F", "#FC9494"],
      },
    ],
  };

  //Apply button function for analytic
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("M/DD/YYYY") +
        " - " +
        picker.endDate.format("M/DD/YYYY")
    );
    const dayStart = dayjs(picker.startDate).format("M/DD/YYYY");

    const dayEnd = dayjs(picker.endDate).format("M/DD/YYYY");

    setSDate(dayStart);
    setEDate(dayEnd);

    props.getAnalytic(type, dayStart, dayEnd);
  };

  //Cancel button function for analytic
  // const handleCancel = (event, picker) => {
  //   picker.element.val("");

  //   props.getAnalytic(type, startDate, endDate);
  // };

  // Function to handle copying the URL
  const copyUrlToClipboard = () => {
    const urlElement = document.getElementById("link");
    const tempInput = document.createElement("input");
    tempInput.value = urlElement.textContent;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    Toast("Success", "Copy to Clipboard done.");
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Dashboard</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/subadmin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {!subAdmin ? (
        <span>Please Wait its loading...</span>
      ) : (
        <div class="row">
          <div class="col-lg-12 col-md-2 col-sm-12">
            <div className="card custom-card">
              <div style={{ paddingTop: "20px", paddingLeft: "20px" }}>
                <div style={{ marginBottom: "5px" }}>
                  {/* adminId show */}
                  Admin ID is <strong>{subAdmin.serialId}</strong>
                </div>
                <p>Click on the link below to share with your agency.</p>
              </div>

              <div
                className="card-body"
                style={{
                  paddingLeft: "20px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {/* generate a link for */}
                <Typography id="link">
                  {/* {`https://www.ARlive.com/sign-up/host?admin=${subAdmin && subAdmin.serialId}`} */}
                  {`${frontendUrl}/agency/signup?admin=${
                    subAdmin && subAdmin.serialId
                  }`}
                </Typography>
                <Button
                  onClick={copyUrlToClipboard}
                  color="primary"
                  size="small"
                >
                  Copy
                </Button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="card stats-card">
                  <div
                    class="card-body pointer-cursor"
                    onClick={() => handleAnalytic("USER")}
                  >
                    <div class="stats-info">
                      <h5 class="card-title">
                        {/* {dashboard.totalUser ? dashboard.totalUser : 0} */}
                        {subAdmin.agencies.length || 0}
                        {/* <span class="stats-change stats-change-danger">-8%</span> */}
                      </h5>
                      <p class="stats-text">Total Agency</p>
                    </div>
                    <div class="stats-icon change-danger">
                      <i class="material-icons">people_alt</i>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-lg-3">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => handleAnalytic("LIVE USER")}
                >
                  <div class="stats-info">
                    <h5 class="card-title">
                      {dashboard.liveUser ? dashboard.liveUser : 0}
                      300
                      <span class="stats-change stats-change-danger">-8%</span>
                    </h5>
                    <p class="stats-text">Total Coins</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">post_add</i>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(null, {
  getDashboard,
  getProfile,
  getSetting,
  getAnalytic,
})(SubAdminDashboard);
