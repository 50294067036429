import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component

import AgencyDashboard from "../component/masterAgency/AgencyDashboard.js";

//loader
import Spinner from "./Spinner.js";
import Profile from "../component/masterAgency/Profile.js";
import MasterAgencyNavbar from "../component/navbar/MasterAgencyNavbar.js";
// import AgencyWithDrawalTable from "../component/table/AgencyWithDrawalTable.js";
import AgencyLoginPage from "../component/masterAgency/AgencyLogin.js";
import AgencyTopNav from "../component/masterAgency/AgencyTopNav.js";
import CoinManage from "../component/masterAgency/CoinManage.js";
import DiamondHistory from "../component/masterAgency/DiamondHistory.js";

const MasterAgency = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/masterAgency") {
      history.push("/masterAgency/dashboard");
    } // eslint-disable-next-line
  }, []);

  const token = localStorage.getItem("TOKEN");

  // const { agency } = useSelector((state) => state?.agency?.agency);

  return (
    <>
      <div class="page-container">
        <MasterAgencyNavbar />
        <div class="page-content">
          <AgencyTopNav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={AgencyDashboard}
              />
              <Route
                path={`${location.path}/profile`}
                exact
                component={Profile}
              />
              <Route
                path={`${location.path}/history/:status`}
                exact
                component={DiamondHistory}
              />
              <Route
                path={`${location.path}/coins`}
                exact
                component={CoinManage}
              />
              {/* <Route
                  path={`${location.path}/withdraw`}
                  exact
                  component={AgencyWithDrawalTable}
                /> */}
            </Switch>
            <Spinner />
          </div>
        </div>
      </div>
      {/* <div>
        <Switch>
          <Route
            exact
            path={`${location.path}/login`}
            component={AgencyLoginPage}
          />
          <Route
            exact
            path={`${location.path}/signup`}
            component={AgencySignUpPage}
          />
        </Switch>
      </div> */}
    </>
  );
};

export default MasterAgency;
