/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "bootstrap-daterangepicker/daterangepicker.css";

// action
import { connect, useSelector } from "react-redux";
import { tranferDiamondsToUser } from "../../store/agency/action";
import Modal from "../TopupReseller/Modal";
import { getProfile } from "../../store/agency/action";
import axios from "axios";

const CoinManagement = (props) => {
  const [infoError, setInfoError] = useState({
    uniqueId: "",
    diamonds: "",
    role: "",
  });

  const [uniqueId, setUniqueId] = useState("");
  const [diamonds, setDiamonds] = useState("");
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = useState(null);
  const role = "user";

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    props.getProfile();
  }, []);
  const { agency } = useSelector((state) => state?.agency?.agency);

  const hanldeSendDiamonds = () => {
    let data = {
      uniqueId,
      agencyId: agency._id,
      diamonds,
    };
    props.tranferDiamondsToUser(data);
    handleClose();
  };
  useEffect(() => {
    // Fetch user data when the uniqueId is set to lenght =6
    if (role && uniqueId.length === 6) {
      fetchUserData();
    }
  }, [role, uniqueId]);
  const fetchUserData = async () => {
    try {
      console.log("role is ", role, "unique id is ", uniqueId);
      const response = await axios.get(
        `/super-admin/profile/${role}/${uniqueId}`
      );
      // if (!response) {
      //   Toast(`error,No ${role} found with this id`);
      // }
      const data = response.data.bioData;
      console.log("🚀 ~ fetchUserData ~ data:", data);

      if (response.status === 200) {
        setUserData(data); // Set user data
      } else {
        console.error(data.message || "Error fetching user data");
        setUserData(null);
      }
    } catch (error) {
      console.error(error);
      setUserData(null);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Coin Management</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/coins" className="text-danger">
                    coins
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-2 col-sm-12">
          <div class="row">
            <div class="col-lg-3">
              <Link to="/agency/history/sent">
                <div class="card stats-card">
                  <div class="card-body pointer-cursor">
                    <div class="stats-info">
                      <p class="stats-text">Sent History</p>
                    </div>
                    <div class="stats-icon change-pink">
                      <i class="material-icons">post_add</i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-3">
              <Link to="/agency/history/received">
                <div class="card stats-card">
                  <div class="card-body pointer-cursor">
                    <div class="stats-info">
                      <p class="stats-text ">Received History</p>
                    </div>
                    <div class="stats-icon change-success">
                      <i class="material-icons">account_balance_wallet</i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-3">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => handleOpen()}
                >
                  <div class="stats-info">
                    <p class="stats-text">Share Coins</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">post_add</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card stats-card">
                <div class="card-body pointer-cursor">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {/* {dashboard.liveUser ? dashboard.liveUser : 0} */}
                      {agency?.topUpAgencyDetails?.diamonds
                        ? agency.topUpAgencyDetails?.diamonds
                        : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Total Coins</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">post_add</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal open */}

      <Modal open={open} setOpen={setOpen}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title mt-3">Share Coins Information</h4>
          </div>
          <div className="card-content">
            <div className="card-body">
              <form autoComplete="off">
                <div className="form-body">
                  <div className="row">
                    {/* <div className="col-12 mb-3 mt-2">
                      <div className="form-group">
                        <label htmlFor="role">Role</label>
                        <select
                          id="role"
                          className="form-control"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="user">User</option>
                          <option value="master-topup-agency">Master Topup Agency</option>
                          <option value="topup-agency">Topup Agency</option>
                        </select>
                      </div>
                    </div> */}

                    <div className="col-12">
                      <div className="form-group">
                        <label for="first-name-vertical">Enter UniqueID</label>
                        <input
                          type="text"
                          id="first-name-vertical"
                          className="form-control"
                          name="name"
                          value={uniqueId}
                          onChange={(e) => {
                            setUniqueId(e.target.value);
                            if (!e.target.value) {
                              return setInfoError({
                                ...infoError,
                                uniqueId: "uniqueId is Required!",
                              });
                            } else {
                              return setInfoError({
                                ...infoError,
                                uniqueId: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {userData && (
                      <div>
                        <h4 className="card-title mt-2">Profile Data</h4>
                        <p>Name: {userData.name}</p>
                        <p>Email: {userData.email}</p>
                        <p>
                          Unique Id:{" "}
                          {userData.uniqueId
                            ? userData.uniqueId
                            : userData.serialId}
                        </p>
                        Profile :
                        {userData.image && (
                          <img
                            src={userData.image}
                            alt="User Avatar"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              objectFit: "cover",
                              display: "block",
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                        )}
                      </div>
                    )}
                    <div className="ml-2 mb-3 mt-2">
                      {infoError.uniqueId && (
                        <div className="pl-1 text-left pb-1">
                          <span className="text-red">{infoError.uniqueId}</span>
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label for="email-id-vertical">Coins</label>
                        <input
                          type="number"
                          id="email-id-vertical"
                          className="form-control"
                          name="email-id"
                          value={diamonds}
                          onChange={(e) => {
                            setDiamonds(e.target.value);
                            if (!e.target.value) {
                              return setInfoError({
                                ...infoError,
                                diamonds: "Diamonds is Required!",
                              });
                            } else {
                              return setInfoError({
                                ...infoError,
                                diamonds: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="ml-2 mb-3 mt-2">
                      {infoError.diamonds && (
                        <div className="pl-1 text-left pb-1">
                          <span className="text-red">
                            {infoError?.diamonds}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-danger me-1 mb-1"
                        onClick={hanldeSendDiamonds}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(null, {
  getProfile,
  tranferDiamondsToUser,
})(CoinManagement);
