import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH_SUBADMIN,
  GET_SUBADMIN,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_SUBADMIN,
  REGISTER_SUBADMIN_FAILURE,
  SET_SUBADMIN,
  UNSET_SUBADMIN,
  UPDATE_PROFILE,
  ACTIVE_AGENCY_SUBADMIN,
  REJECT_AGENCY_SUBADMIN,
  GET_ALL_SUBADMIN_LIST,
  GET_ALL_HOSTS_SUBADMIN,
} from "./types";
import { WebAppName, baseURL, key } from "../../util/Config";

export const register = (userData) => (dispatch) => {
  const { name, email, password } = userData;

  axios
    .post("subadmin/create-sub-admin", {
      name,
      email,
      password,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast(
          "success",
          "You have successfully registered as a Admin in unique live. Please wait for approval."
        );
        setTimeout(() => {
          window.location.href = "/subadmin/login";
        }, 3000);
        dispatch({ type: REGISTER_SUBADMIN });
      } else {
        Toast("error", res.data.message);
        dispatch({ type: REGISTER_SUBADMIN_FAILURE });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: REGISTER_SUBADMIN_FAILURE });
    });
};

export const getSubAdmin =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    fetch(
      `${baseURL}subadmin/sub-admins?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_SUBADMIN,
            payload: {
              subAdmin: res.subAdmins,

              total: res.total,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };
//getSubadminsList for agency signup
export const getAllSubAdminsUniqueIds =
  () => (dispatch) => {
    axios
      .get(`subadmin/all`)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: GET_ALL_SUBADMIN_LIST,
            payload: res.data.subAdmins,
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };



export const handleBlockUnblockSwitch = (subAdminId) => (dispatch) => {
  axios
    .patch(`agency/blockUnblock/${subAdminId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BLOCK_UNBLOCK_SWITCH_SUBADMIN,
          payload: res.data.subAdmin,
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const login = (loginData) => async (dispatch) => {
  axios
    .post("/subadmin/login", loginData)
    .then((res) => {
      console.log("res.data for login subAdmin", res.data);
      if (res.data.status) {
        Toast("success", `You have successfully logged in ${WebAppName}.`);
        dispatch({ type: SET_SUBADMIN, payload: res.data.token });
        // localStorage.setItem("subAdmin", JSON.stringify(res.data.subAdmin));
        setTimeout(() => {
          window.location.href = "/subadmin/dashboard";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: action.js:22 ~ login ~ error:", error);
      return console.log(error);
    });
};
export const getProfile = () => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get("/subadmin/profile", {
      headers: {
        Authorization: `${authToken}`,
      }
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.data.subAdmin });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("subadmin/update-password", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_SUBADMIN });
          window.location.href = "/subadmin/login";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("subadmin/update-profile", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.subAdmin });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};


export const activeAgency = (agencyId) => (dispatch) => {
  console.log("agencyId in action", agencyId);
  axios
    .put(`/subadmin/active-agency/${agencyId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ACTIVE_AGENCY_SUBADMIN,
          payload: res.data.subAdmin,
        });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const rejectAgency = (agencyId) => (dispatch) => {
  axios
    .put(`/subadmin/reject-agency/${agencyId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: REJECT_AGENCY_SUBADMIN,
          payload: res.data.subAdmin,
        });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};


//get all hosts 
export const getAllHosts = () => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get("/subadmin/hosts/all", {
      headers: {
        Authorization: `${authToken}`,
      }
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_ALL_HOSTS_SUBADMIN, payload: res.data.hosts });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};
