import React from "react";
const DiamondHistoryTable = (props) => {
  const data = Array.isArray(props.data) ? props.data : [];
  console.log("🚀 ~ DiamondHistoryTable ~ data:", data)
  const filteredData = data.filter((item) => {
    return item.status === props.status;
  });
  return (
    <table className="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>{props.status === "sent" ? "Send to" : "Receive from"}</th>
          <th>Email</th>
          <th>User Name</th>
          <th>Diamond</th>
          <th>Transfer Date</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.length > 0 ? (
          filteredData.map((data, index) => {
            const recordNumber =
              index + 1 + (props.activePage - 1) * props.rowsPerPage;
            return (
              <tr key={index}>
                {/* <td>{index + 1}</td>   */}
                <td>{recordNumber}</td>

                <td>{data.role}</td>
                <td>{data.email}</td>
                <td className="text-info">{data.name}</td>
                <td>{data.quantity}</td>
                <td>{data.createdAt}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default DiamondHistoryTable;

// import React from "react";

// const DiamondHistoryTable = (props) => {
//     const { data, activePage, rowsPerPage } = props;

//     return (
//         <table className="table table-striped mt-5">
//             <thead>
//                 <tr>
//                     <th>No.</th>
//                     <th>Role</th>
//                     <th>Email</th>
//                     <th>User Name</th>
//                     <th>Diamond</th>
//                     <th>Transfer Date</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {data?.length > 0 ? (
//                     data.map((data, index) => {
//                         const calculatedIndex = (activePage - 1) * rowsPerPage + (index + 1);
//                         return (
//                             <tr key={calculatedIndex}>
//                                 <td>{calculatedIndex}</td>
//                                 <td>User</td>
//                                 <td>{data.email}</td>
//                                 <td className="text-info">{data.name}</td>
//                                 <td>{data.quantity}</td>
//                                 <td>{data.createdAt}</td>
//                             </tr>
//                         );
//                     })
//                 ) : (
//                     <tr>
//                         <td colSpan="8" align="center">
//                             Nothing to show!!
//                         </td>
//                     </tr>
//                 )}
//             </tbody>
//         </table>
//     );
// };

// export default DiamondHistoryTable;
