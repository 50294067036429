import React, { Suspense, useEffect, useState } from "react";

// routing
import { Switch, BrowserRouter, Route } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";
import { SET_AGENCY } from "./store/agency/types";
import { SET_MASTER_AGENCY } from "./store/masterAgency/types";

import { IdleTimeoutManager } from "idle-timer-manager";

//Components
import Login from "./pages/LoginPage";
import UnlockScreenPage from "./pages/UnlockScreenPage";
import Page404 from "./pages/Page404";
// import Admin from "./pages/Admin";
import Agency from "./pages/Agency";
import SubAdmin from "./pages/SubAdmin";
import TopUpReseller from "./pages/TopupReseller";
import AuthRouter from "./util/AuthRouter";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import axios from "axios";
import Registration from "./pages/Registration";
import UpdateCode from "./pages/UpdateCode";
import HomePage from "./pages/Home";
import MasterAgency from "./pages/MasterAgency";
import AgencyAuthRouter from "./util/AgencyAuthRouter";
import AgencyLogin from "./component/Agency/AgencyLogin";
import MasterAgencyAuthRouter from "./util/MasterAgencyAuthRouter";
import MasterAgencyLoginPage from "./component/masterAgency/AgencyLogin";
function App() {
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);

  // const { isAuth } = useSelector((state) => state.admin);
  const { isAgency } = useSelector((state) => state.agency);
  const { isMasterAgency } = useSelector((state) => state.masterAgency);

  const token = localStorage.getItem("TOKEN");

  const key = localStorage.getItem("KEY");

  // useEffect(() => {
  //   axios
  //     .get("/login")
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       setLogin(res.data.login);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (!token && !key) return;
  //   dispatch({ type: SET_ADMIN, payload: token });
  // }, [token, key, dispatch]);
  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_AGENCY, payload: token });
  }, [token, key, dispatch]);
  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_MASTER_AGENCY, payload: token });
  }, [token, key, dispatch]);

  // useEffect(() => {
  //   const manager = new IdleTimeoutManager({
  //     timeout: 1800, //30 min (in sec)
  //     onExpired: (time) => {
  //       dispatch({ type: UNSET_ADMIN });
  //       return (window.location.href = "/");
  //     },
  //   });

  //   return () => {
  //     manager.clear();
  //   }; //eslint-disable-next-line
  // }, []);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            {/* <AuthRouter
              exact
              path="/"
              component={login ? Login : Registration}
            /> */}
            {/* <AuthRouter exact path="/" component={Login} /> */}

            {/* <AuthRouter exact path="/" component={Login} /> */}
            {/* <AuthRouter exact path="/code" component={UpdateCode} /> */}
            {/* <AuthRouter exact path="/login" component={Login} /> */}
            {/* <AuthRouter path="/Registration" component={Registration} /> */}
            <AgencyAuthRouter exact path="/" component={AgencyLogin} />
            <MasterAgencyAuthRouter
              exact
              path="/masterAgency/login"
              component={MasterAgencyLoginPage}
            />
            {/* <Route exact path="/" component={HomePage} /> */}

            <AuthRouter exact path="/unlock" component={UnlockScreenPage} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/changePassword/:id"
              component={ChangePassword}
            />
            {isAgency && <Route path="/agency" component={Agency} />}
            {/* <Route path="/admin" component={Admin} /> */}
            {isMasterAgency && (
              <Route path="/masterAgency" component={MasterAgency} />
            )}
            {/* <Route path="/subadmin" component={SubAdmin} /> */}
            {/* <Route path="/top-seller" component={TopUpReseller} /> */}
            <Route component={Page404} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
