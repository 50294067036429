import React from "react";
import Male from "../../assets/images/male.png";
import { Tooltip } from "@material-ui/core";
import { rejectAgency, activeAgency } from '../../store/sub-admin/action';
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const SubAdminAgencyTableList = (props) => {
  const history = useHistory();

  const handleBlockUnblockSwitch_ = (agencyId) => {
    props.rejectAgency(agencyId);
  };

  const handleActiveAgency = (agencyId) => {
    props.activeAgency(agencyId);
  };

  const handleUserInfo = (agency) => {
    history.push(`/subadmin/agency/detail?id=${agency._id}`);
  };

  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Unique ID</th>
          <th>Admin ID</th>
          <th>Profile</th>
          <th>Email</th>
          <th>User Name</th>
          <th>No of Hosts</th>
          <th>reject</th>
          <th>Info</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.serialId}</td>
                <td>{data?.agencyRequest.adminId}</td>
                <td>
                  <img
                    height="50px"
                    width="50px"
                    alt="app"
                    src={data.topSeller?.image ? data.topSeller?.image : Male}
                    style={{
                      boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                      border: "2px solid #fff",
                      borderRadius: 10,
                      objectFit: "cover",
                      display: "block",
                    }}
                    className="mx-auto"
                  />
                </td>
                <td>{data?.email}</td>
                <td className="text-info">{data?.name}</td>
                <th>{data?.hosts?.length || 0}</th>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={data.isBlock}
                      onChange={() =>
                        handleBlockUnblockSwitch_(data._id)
                      }
                    />
                    <span className="slider">
                      <p
                        style={{
                          fontSize: 12,
                          marginLeft: `${data.isBlock ? "-24px" : "35px"
                            }`,
                          color: "#000",
                          marginTop: "6px",
                        }}
                      >
                        {data.isBlock ? "Yes" : "No"}
                      </p>
                    </span>
                  </label>
                </td>
                <td>
                  <Tooltip title="Info">
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={() => handleUserInfo(data)}
                    >
                      <i className="fas fa-info-circle fa-lg"></i>
                    </button>
                  </Tooltip>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={data.isBlock}
                      onChange={() =>
                        handleActiveAgency(data._id)
                      }
                    />
                    <span className="slider">
                      <p
                        style={{
                          fontSize: 12,
                          marginLeft: `${data.agencyRequest.status == "pending" ? "-24px" : "35px"
                            }`,
                          color: "#000",
                          marginTop: "6px",
                        }}
                      >
                        {data.agencyRequest.status == "pending" ? "Yes" : "No"}
                      </p>
                    </span>
                  </label>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default connect(null, { activeAgency, rejectAgency })(SubAdminAgencyTableList);
;
