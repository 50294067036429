import {
  BLOCK_UNBLOCK_SWITCH_SUBADMIN,
  GET_SUBADMIN,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_SUBADMIN,
  REGISTER_SUBADMIN_FAILURE,
  SET_SUBADMIN,
  UNSET_SUBADMIN,
  SEND_EMAIL,
  UPDATE_PROFILE,
  ACTIVE_AGENCY_SUBADMIN,
  REJECT_AGENCY_SUBADMIN,
  GET_ALL_SUBADMIN_LIST,
  GET_ALL_HOSTS_SUBADMIN,
} from "./types";
import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";

const initialState = {
  isAuth: false,
  subAdmin: [],
  totalSubAdmin: 0,
  activeSubAdmin: 0,
  history: [],
  totalHistorySubAdmin: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
};

const subAdminReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case REGISTER_SUBADMIN:
      return {
        ...state,
        subAdmin: action.payload.subAdmin,
      };

    case REGISTER_SUBADMIN_FAILURE:
      return state;
    case GET_SUBADMIN:
      return {
        ...state,
        subAdmin: action.payload.subAdmin,

        totalSubAdmin: action.payload.total,
      };
      case GET_ALL_SUBADMIN_LIST:
        return {
          ...state,
          subadmin: action.payload,
          };
    case BLOCK_UNBLOCK_SWITCH_SUBADMIN:
      return {
        ...state,
        subAdmin: state.subAdmin.map((subAdmin) => {
          if (subAdmin._id === action.subAdmin._id)
            return {
              ...subAdmin,
              isBlock: action.payload.isBlock,
            };
          else return subAdmin;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload.id) return action.payload.data;
          else return user;
        }),
      };
    case SET_SUBADMIN:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      return {
        ...state,
        isAuth: true,
        subAdmin: decoded,
      };
    case UNSET_SUBADMIN:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("admin");
      localStorage.removeItem("KEY");

      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        subAdmin: {},
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        // subAdmin: {
        //   ...state.subAdmin,
        //   _id: action.payload._id,
        //   name: action.payload.name,
        //   email: action.payload.email,
        //   image: action.payload.image,
        //   flag: action.payload.flag,
        // },
        subAdmin: { ...state.subAdmin, subAdmin: action.payload }
      };
    case ACTIVE_AGENCY_SUBADMIN:
      return {
        ...state,
        subAdmin: { ...state, subAdmin: action.payload }
      };
    case REJECT_AGENCY_SUBADMIN:
      return {
        ...state,
        subAdmin: { ...state, subAdmin: action.payload }
      }
      case GET_ALL_HOSTS_SUBADMIN:
        return {
          ...state,
          hosts: { ...state, hosts: action.payload }
        }
  
    default:
      return state;
  }
};

export default subAdminReducer;
