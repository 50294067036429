/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { Chart as ChartJS } from "chart.js/auto";
// import { Line, Doughnut } from "react-chartjs-2";

//dayjs
import dayjs from "dayjs";

//datepicker
// import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

// action
import { getDashboard, getAnalytic } from "../../store/dashboard/action";
import { getSetting } from "../../store/setting/action";
import { connect, useSelector } from "react-redux";
import { tranferDiamondsToUser } from "../../store/top-seller/action";
import Modal from "./Modal";
import { getTopSellerProfile } from "../../store/top-seller/action";

const TopupResellerDashboard = (props) => {
  let label = [];
  let data = [];
  let data1 = [];
  const [type, setType] = useState("USER");
  const topSeller = JSON.parse(localStorage.getItem("topSeller"));
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const analytic = useSelector((state) => state.dashboard.analytic);
  const setting = useSelector((state) => state.setting.setting);

  const [infoError, setInfoError] = useState({
    userId: "",
    diamonds: "",
  });
  const [userId, setUserId] = useState("");
  const [diamonds, setDiamonds] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.getTopSellerProfile(topSeller._id);
    setOpen(false)
  };
  useEffect(() => {
    props.getTopSellerProfile(topSeller._id);
  }, [])
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const startDate = dayjs(firstDay).format("M/DD/YYYY");
  const endDate = dayjs(lastDay).format("M/DD/YYYY");
  const [sDate, setSDate] = useState(startDate);
  const [eDate, setEDate] = useState(endDate);
  const [analyticData, setAnalyticData] = useState([]);

  useEffect(() => {
    props.getDashboard();
    props.getSetting();
    props.getAnalytic("USER", sDate, eDate); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAnalyticData(analytic);
  }, [analytic]);

  if (analyticData.length > 0) {
    if (type === "REVENUE" && analyticData.length === 1) {
      //eslint-disable-next-line
      analyticData[0]?.coinRevenue?.map((date) => {
        label.push(date._id);
      });
      //eslint-disable-next-line
      analyticData[0]?.vipRevenue?.map((date) => {
        label.push(date._id);
      });
      const uniqLabel = [...new Set(label)];
      //eslint-disable-next-line
      uniqLabel.map((data__) => {
        const count = analyticData[0]?.coinRevenue?.find(
          (element) => element._id === data__
        );
        if (count) {
          data.push(setting.currency === "$" ? count.dollar : count.rupee);
        } else {
          data.push(0);
        }
        const count_ = analyticData[0]?.vipRevenue?.find(
          (element) => element._id === data__
        );
        if (count_) {
          data1.push(setting.currency === "$" ? count_.dollar : count_.rupee);
        } else {
          data1.push(0);
        }
      });
    } else {
      //eslint-disable-next-line
      analyticData.map((data_) => {
        label.push(data_._id);
        data.push(data_.count);
      });
    }
  }

  const handleAnalytic = (type) => {
    setSDate(startDate);
    setEDate(endDate);
    setType(type);
    if (type !== "ACTIVE USER") {
      props.getAnalytic(type, startDate, endDate);
    }
  };

  const chartData = {
    labels: label,
    datasets: [
      {
        label: type,
        data: data,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgb(255, 99, 132)",
        lineTension: 0.5,
      },
    ],
  };
  const multiLineChartData = {
    labels: label,
    datasets: [
      {
        label: "Coin Revenue",
        data: data,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgb(255, 99, 132)",
        lineTension: 0.5,
      },
      {
        label: "VIP Revenue",
        data: data1,
        fill: true,
        backgroundColor: "rgb(205,235,255,0.1)",
        borderColor: "#68B9F0",
        lineTension: 0.5,
      },
    ],
  };

  const pieChartData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Total User", "Active User"],
    datasets: [
      {
        data: [dashboard.totalUser, dashboard.activeUser],
        backgroundColor: ["#E8538F", "#FC9494"],
        hoverBackgroundColor: ["#E8538F", "#FC9494"],
      },
    ],
  };

  //Apply button function for analytic
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("M/DD/YYYY") +
      " - " +
      picker.endDate.format("M/DD/YYYY")
    );
    const dayStart = dayjs(picker.startDate).format("M/DD/YYYY");

    const dayEnd = dayjs(picker.endDate).format("M/DD/YYYY");

    setSDate(dayStart);
    setEDate(dayEnd);

    props.getAnalytic(type, dayStart, dayEnd);
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker.element.val("");

    props.getAnalytic(type, startDate, endDate);
  };

  const hanldeSendDiamonds = () => {
    let data = {
      userId,
      topSellerId: topSeller._id,
      diamonds
    };
    props.tranferDiamondsToUser(data);
    handleClose();
  }
  const { topSellerProfile } = useSelector((state) => state.topSeller);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Dashboard</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/top-seller/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-2 col-sm-12">
          {/* <div className="card custom-card">
            <div style={{ paddingTop: "20px", paddingLeft: "20px" }}>
              <div style={{ marginBottom: "5px" }}>
                Golden TopUp ID is <strong>511353980</strong>Golden TopUp ID is
              </div>
              <p>Click on the link below to share with your TopUp Agent .</p>
            </div>

            <div
              id="link"
              className="card-body"
              style={{
                paddingLeft: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              https://www.ARlive.com/sign-up/top-up-seller?goldertopup=511353980
            </div>
          </div> */}

          <div class="row">
            <div class="col-lg-3">
              <div class="card stats-card">
                <Link to='/top-seller/history/send-diamonds'>
                  <div
                    class="card-body pointer-cursor"
                  >
                    <div class="stats-info">
                      <p class="stats-text">Send Diamonds</p>
                    </div>
                    <div class="stats-icon change-pink">
                      <i class="material-icons">post_add</i>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card stats-card">
                <Link to='/top-seller/history/recieve-diamonds'>
                  <div
                    class="card-body pointer-cursor"
                  >
                    <div class="stats-info">
                      <p class="stats-text ">Received Diamonds</p>
                    </div>
                    <div class="stats-icon change-success">
                      <i class="material-icons">account_balance_wallet</i>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => handleOpen()}
                >
                  <div class="stats-info">
                    <p class="stats-text">Share Diamonds</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">post_add</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                >
                  <div class="stats-info">
                    <h5 class="card-title">
                      {/* {dashboard.liveUser ? dashboard.liveUser : 0} */}
                      {topSellerProfile?.diamonds ? topSellerProfile.diamonds : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Total Diamonds</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">post_add</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal open */}

      <Modal open={open} setOpen={setOpen}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title mt-3">Share Diamonds Information</h4>
          </div>
          <div className="card-content">
            <div className="card-body">
              <form autoComplete="off">
                <div className="form-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label for="first-name-vertical">Enter UniqueId</label>
                        <input
                          type="text"
                          id="first-name-vertical"
                          className="form-control"
                          name="name"
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value);
                            if (!e.target.value) {
                              return setInfoError({
                                ...infoError,
                                userId: "userId is Required!",
                              });
                            } else {
                              return setInfoError({
                                ...infoError,
                                userId: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="ml-2 mb-3 mt-2">
                      {infoError.userId && (
                        <div className="pl-1 text-left pb-1">
                          <span className="text-red">
                            {infoError.userId}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label for="email-id-vertical">Diamonds</label>
                        <input
                          type="number"
                          id="email-id-vertical"
                          className="form-control"
                          name="email-id"
                          value={diamonds}
                          onChange={(e) => {
                            setDiamonds(e.target.value);
                            if (!e.target.value) {
                              return setInfoError({
                                ...infoError,
                                diamonds: "Diamonds is Required!",
                              });
                            } else {
                              return setInfoError({
                                ...infoError,
                                diamonds: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="ml-2 mb-3 mt-2">
                      {infoError.diamonds && (
                        <div className="pl-1 text-left pb-1">
                          <span className="text-red">
                            {infoError?.diamonds}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-danger me-1 mb-1"
                        onClick={hanldeSendDiamonds}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default connect(null, { getDashboard, getSetting, getAnalytic, tranferDiamondsToUser, getTopSellerProfile })(
  TopupResellerDashboard
);
