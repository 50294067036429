import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  UPDATE_PROFILE,
  REGISTER_MASTER_AGENCY,
  REGISTER_MASTER_AGENCY_FAILURE,
  UNSET_MASTER_AGENCY,
  SET_MASTER_AGENCY,
} from "./types";

import { WebAppName, baseURL, key } from "../../util/Config";

export const register = (userData) => (dispatch) => {
  const { name, email, password, adminId } = userData;

  axios
    .post("/master-agency/create-agency", {
      name,
      email,
      password,
      adminId,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast(
          "success",
          `You have successfully registered as a Agency in ${WebAppName}. Please wait for approval.`
        );
        setTimeout(() => {
          window.location.href = "/masterAgency/login";
        }, 3000);
        dispatch({ type: REGISTER_MASTER_AGENCY });
      } else {
        Toast("error", res.data.message);
        dispatch({ type: REGISTER_MASTER_AGENCY });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: REGISTER_MASTER_AGENCY_FAILURE });
    });
};

// export const getAgency =
//   (start, limit, searchValue, sDate, eDate) => (dispatch) => {
//     const requestOptions = {
//       method: "GET",
//       headers: { key: key },
//     };
//     fetch(
//       `${baseURL}agency/agencies?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((res) => {
//         if (res.status) {
//           dispatch({
//             type: GET_AGENCY,
//             payload: {
//               agency: res.agencies,

//               total: res.total,
//             },
//           });
//         } else {
//           Toast("error", res.message);
//         }
//       })
//       .catch((error) => Toast("error", error.message));
//   };

export const login = (data, history) => (dispatch) => {
  axios
    .post("/master-agency/login", data)
    .then((res) => {
      if (res.data.status) {
        Toast(`success", "You have successfully logged in ${WebAppName}.`);
        dispatch({ type: SET_MASTER_AGENCY, payload: res.data.token });
        setTimeout(() => {
          window.location.href = "/masterAgency/dashboard";
        }, 1000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: action.js:22 ~ login ~ error:", error);
      return console.log(error);
    });
};
export const getProfile = () => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get("/master-agency/profile", {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.data.masterAgency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("/master-agency/update-password", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_MASTER_AGENCY });
          window.location.href = "/masterAgency/login";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("/master-agency/update-profile", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.masterAgency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//transfer to topup agency and user
export const tranferDiamondsMasterAgency = (bodydata) => async (dispatch) => {
  try {
    const { data } = await axios.put(`/master-agency/transfer/coins`, bodydata);
    console.log("data", data);
    dispatch({ type: UPDATE_PROFILE, payload: data.masterAgency });
    Toast("success", data.message);
  } catch (error) {
    console.log("error", error);
    const errorMessage = error.response?.data?.message || "An error occurred";
    Toast("error", errorMessage);
  }
};
