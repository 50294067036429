export const GET_AGENCY = "GET_AGENCY";
export const REGISTER_AGENCY = "REGISTER_AGENCY";
export const REGISTER_AGENCY_FAILURE = "REGISTER_AGENCY_FAILURE";
export const BLOCK_UNBLOCK_SWITCH_AGENCY = "BLOCK_UNBLOCK_SWITCH_AGENCY";
export const GET_ALL_AGENCY_HOSTS = "GET_ALL_AGENCY_HOSTS";
export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";
export const SET_AGENCY = "SET_AGENCY";
export const UNSET_AGENCY = "UNSET_AGENCY";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_AGENCY_DETAILS = "GET_AGENCY_DETAILS";

export const ACTIVE_HOST_AGENCY = "ACTIVE_HOST_AGENCY";
export const REJECT_HOST_AGENCY = "REJECT_HOST_AGENCY";
