import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";

const AgencyAuthRouter = ({
  component: Component,
  isMasterAgency,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isMasterAgency === true ? (
        <Redirect to="/masterAgency" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  isMasterAgency: state.masterAgency.isMasterAgency,
});

export default connect(mapStateToProps)(AgencyAuthRouter);
