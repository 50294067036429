export const GET_TOP_SELLER = "GET_TOP_SELLER";
export const REGISTER_TOP_SELLER = "REGISTER_TOP_SELLER";
export const REGISTER_TOP_SELLER_FAILURE = "REGISTER_TOP_SELLER_FAILURE";
export const BLOCK_UNBLOCK_SWITCH_TOP_SELLER =
  "BLOCK_UNBLOCK_SWITCH_TOP_SELLER";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN_TOP_SELLER = "EDIT_COIN_TOP_SELLER";
export const GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY = "GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY";
export const SET_TOP_SELLER = "SET_TOP_SELLER";
export const UNSET_TOP_SELLER = "UNSET_TOP_SELLER";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_TOP_SELLER_PROFILE = "GET_TOP_SELLER_PROFILE";
export const GET_TOP_SELLER_DIAMOND_HISTORY = "GET_TOP_SELLER_DIAMOND_HISTORY";
