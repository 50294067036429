import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH_TOP_SELLER,
  GET_TOP_SELLER,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_TOP_SELLER,
  REGISTER_TOP_SELLER_FAILURE,
  SET_TOP_SELLER,
  UNSET_TOP_SELLER,
  UPDATE_PROFILE,
  GET_TOP_SELLER_PROFILE,
  EDIT_COIN_TOP_SELLER,
  GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY,
  GET_TOP_SELLER_SEND_DIAMOND_HISTORY,
  GET_TOP_SELLER_RECIEVED_DIAMOND_HISTORY,
  GET_TOP_SELLER_DIAMOND_HISTORY,
} from "./types";
import { WebAppName, baseURL, key } from "../../util/Config";

export const register = (userData) => (dispatch) => {
  const { name, email, password } = userData;

  axios
    .post("topseller/create-top-seller", {
      name,
      email,
      password,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast(
          "success",
          `You have successfully registered as a Top-Seller in ${WebAppName}. Please wait for approval.`
        );
        setTimeout(() => {
          window.location.href = "/top-seller/login";
        }, 3000);
        dispatch({ type: REGISTER_TOP_SELLER });
      } else {
        Toast("error", res.data.message);
        dispatch({ type: REGISTER_TOP_SELLER_FAILURE });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: REGISTER_TOP_SELLER_FAILURE });
    });
};
export const getTopSellerProfile = (topSellerId) => (dispatch) => {
  axios
    .get(`topseller/profile/${topSellerId}`)
    .then((res) => {
      if (res.data.status) {
        console.log("data fetched", res.data);
        dispatch({ type: GET_TOP_SELLER_PROFILE, payload: res.data.topSeller });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const login = (data) => (dispatch) => {
  axios
    .post("topseller/login", data)
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast("success", "You have successfully logged in unique live.");
        setTimeout(() => {
          window.location.href = "/top-seller/dashboard";
        }, 3000);
        dispatch({ type: SET_TOP_SELLER, payload: res.data.token });
        localStorage.setItem("topSeller", JSON.stringify(res.data.topSeller));
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: action.js:22 ~ login ~ error:", error);
      return console.log(error);
    });
};
export const getTopSeller =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    fetch(
      `${baseURL}topseller/top-sellers?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_TOP_SELLER,
            payload: {
              topSeller: res.topSellers,

              total: res.total,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const handleBlockUnblockSwitch = (topSellerId) => (dispatch) => {
  axios
    .patch(`topseller/blockUnblock/${topSellerId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BLOCK_UNBLOCK_SWITCH_TOP_SELLER,
          payload: res.data.topSeller,
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (bodydata) => async (dispatch) => {
  console.log("data for user", bodydata);
  try {
    const { data } = await axios.put(`/topSeller/edit-diamonds`, bodydata);
    console.log("data", data);
    Toast("success", data.message);
  } catch (error) {
    console.log("error", error);
    Toast("error", error.message);
  }
};

export const getProfile = () => (dispatch) => {
  axios
    .get("topseller/profile")
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.data.topSeller });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("topseller/update-password", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_TOP_SELLER });
          window.location.href = "/top-seller/login";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("topseller/update-profile", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.topSeller });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
//adminToTopSellerDiamondHistory
export const adminToTopSellerDiamondHistory = (adminId) => (dispatch) => {
  const url = `/admin/topSeller/diamondHistory/${adminId}`;
  axios
    .get(url)
    .then((res) => {
      if (res.data.status) {
        console.log("res", res.data);
        dispatch({
          type: GET_ADMIN_TOP_SELLER_DIAMOND_HISTORY,
          payload: {
            diamondsHistoryTopSeller: res.data.admin.diamondsHistory.send,
          },
        });
      } else {
        Toast("Success", res.data?.message);
      }
    })
    .catch((error) => console.log(error));
};

export const topSellerDiamondHistoryComplete = (topSellerId) => (dispatch) => {
  const url = `/topseller/users/diamondHistory/${topSellerId}`;
  axios
    .get(url)
    .then((res) => {
      if (res.data.status) {
        console.log("res", res.data);
        dispatch({
          type: GET_TOP_SELLER_DIAMOND_HISTORY,
          payload: {
            topSellerDiamondHistory: res.data.topSeller.diamondsHistory,
          },
        });
      } else {
        Toast("Success", res.data?.message);
      }
    })
    .catch((error) => console.log(error));
};

export const tranferDiamondsToUser = (bodydata) => async (dispatch) => {
  console.log("data for user", bodydata);
  try {
    const { data } = await axios.put(
      `/topSeller/transfer/diamonds/user`,
      bodydata
    );
    console.log("data", data);
    Toast("success", data.message);
  } catch (error) {
    console.log("error", error);
    Toast("error", error.message);
  }
};
