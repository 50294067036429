import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH_AGENCY,
  GET_AGENCY,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_AGENCY,
  REGISTER_AGENCY_FAILURE,
  SET_AGENCY,
  UNSET_AGENCY,
  UPDATE_PROFILE,
  GET_AGENCY_DETAILS,
  GET_ALL_AGENCY_HOSTS,
  ACTIVE_HOST_AGENCY,
  REJECT_HOST_AGENCY,
} from "./types";
import { WebAppName, baseURL, key } from "../../util/Config";

export const register = (userData) => (dispatch) => {
  const { name, email, password, adminId } = userData;

  axios
    .post("agency/create-agency", {
      name,
      email,
      password,
      adminId,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast(
          "success",
          `You have successfully registered as a Agency in ${WebAppName}. Please wait for approval.`
        );
        setTimeout(() => {
          window.location.href = "/agency/login";
        }, 3000);
        dispatch({ type: REGISTER_AGENCY });
      } else {
        Toast("error", res.data.message);
        dispatch({ type: REGISTER_AGENCY_FAILURE });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: REGISTER_AGENCY_FAILURE });
    });
};

export const getAgency =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    fetch(
      `${baseURL}agency/agencies?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_AGENCY,
            payload: {
              agency: res.agencies,

              total: res.total,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const handleBlockUnblockSwitch = (agencyId) => (dispatch) => {
  axios
    .patch(`agency/blockUnblock/${agencyId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BLOCK_UNBLOCK_SWITCH_AGENCY,
          payload: res.data.agency,
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const login = (data, history) => (dispatch) => {
  axios
    .post("/agency/login", data)
    .then((res) => {
      if (res.data.status) {
        Toast(`success", "You have successfully logged in ${WebAppName}.`);
        dispatch({ type: SET_AGENCY, payload: res.data.token });
        setTimeout(() => {
          window.location.href = "/agency/dashboard";
        }, [1000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: action.js:22 ~ login ~ error:", error);
      return console.log(error);
    });
};
export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const getProfile =
  (status, start, limit, searchValue, sDate, eDate) => (dispatch) => {
    let authToken = localStorage.getItem("TOKEN");
    axios
      .get(
        `/agency/profile?status=${status}&start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log("res,,,,,,,,,,,,,,,,,,,,,,,", res.data.agency);
        if (res.data.status) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: {
              ...res.data.agency,
              total: res.data.agency.totalDiamondHistoryRecords,
            },
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

export const getDetailsSubAdmin = (agencyId) => (dispatch) => {
  console.log("agency details called", agencyId);
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get(`/agency/details/${agencyId}`, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        console.log("data agency", res.data);
        dispatch({ type: GET_AGENCY_DETAILS, payload: res.data.agency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("agency/update-password", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_AGENCY });
          window.location.href = "/";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("agency/update-profile", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.agency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
//get all agency hosts

// //get all hosts
// export const getAllAgencyHosts =
//   (start, limit, searchValue, sDate, eDate) => (dispatch) => {
//     console.log("start------------------------lino 220", start);
//     console.log("limitssssssssssssssssss------356", limit);
//     console.log("search value------------", searchValue);
//     console.log("start datee------------------", sDate);
//     console.log("end dte-------------------------", eDate);
//     let authToken = localStorage.getItem("TOKEN");
//     axios
//       .get(
//         `/agency/hosts?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
//         {
//           headers: {
//             Authorization: `${authToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         console.log("res.data,,,,,,,,,,,,,,,,,,,,,,,",res.data)
//         if (res.data.status) {
//           dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: res.data.hosts });
//         } else {
//           Toast("error", res.data.message);
//         }
//       })
//       .catch((error) => {
//         console.log("error", error.message);
//       });
//   };
export const getAllAgencyHosts =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    console.log("start------------------------lino 220", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    let authToken = localStorage.getItem("TOKEN");
    axios
      .get(
        `/agency/hosts?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log("res.data,,,,,,,,,,,,,,,,,,,,,,,", res.data);
        if (res.data.status) {
          dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: res.data.hosts });
        } else {
          dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: [] }); // Clear the data in the state
          // Toast("error", res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
        dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: [] }); // Clear the data in the state
      });
  };

export const activeHost = (hostId) => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .put(`/agency/host/active/${hostId}`, null, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ACTIVE_HOST_AGENCY,
          payload: res.data.agency,
        });
        console.log("res during activation", res.data.hosts);
        dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: res.data.hosts });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const rejectHost = (hostId) => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .put(`/agency/host/reject/${hostId}`, null, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: REJECT_HOST_AGENCY,
          payload: res.data.agency,
        });
        dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: res.data.hosts });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//transfer to user
export const tranferDiamondsToUser = (bodydata) => async (dispatch) => {
  console.log("data for user", bodydata);
  try {
    const { data } = await axios.put(`/agency/transfer/coins`, bodydata);
    dispatch({ type: UPDATE_PROFILE, payload: data.agency });
    Toast("success", data.message);
  } catch (error) {
    console.log("error", error);
    Toast("error", error.message);
  }
};
