import React from "react";
// import Male from "../../assets/images/male.png";

const DiamondHistoryTable = (props) => {
  const { data, activePage, rowsPerPage } = props;

  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Role</th>
          <th>Email</th>
          <th>User Name</th>
          <th>Diamond</th>
          <th>Transfer Date</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
            const calculatedIndex =
              (activePage - 1) * rowsPerPage + (index + 1);

            return (
              <tr key={calculatedIndex}>
                <td>{calculatedIndex}</td>
                <td>User</td>
                <td>{data.email}</td>
                <td className="text-info">{data.name}</td>
                <td>{data.quantity}</td>
                <td>{data.createdAt}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DiamondHistoryTable;
