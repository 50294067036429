import {
  UPDATE_PROFILE,
  REGISTER_MASTER_AGENCY,
  REGISTER_MASTER_AGENCY_FAILURE,
  SET_MASTER_AGENCY,
  UNSET_MASTER_AGENCY,
  GET_MASTER_AGENCY_DETAILS,
} from "./types";

import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";

const initialState = {
  isMasterAgency: false,
  masterAgency: [],
  totalMasterAgency: 0,
};

const masterAgencyReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case REGISTER_MASTER_AGENCY:
      return {
        ...state,
        masterAgency: action.payload.masterAgency,
      };

    case REGISTER_MASTER_AGENCY_FAILURE:
      return state;
    case GET_MASTER_AGENCY_DETAILS:
      return {
        ...state,
        masterAgency: action.payload.masterAgency,
        totalMasterAgency: action.payload.total,
      };
    case SET_MASTER_AGENCY:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      return {
        ...state,
        isMasterAgency: true,
        masterAgency: decoded,
      };

    case UNSET_MASTER_AGENCY:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("admin");
      localStorage.removeItem("KEY");

      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isMasterAgency: false,
        masterAgency: {},
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        masterAgency: {
          ...state.masterAgency,
          _id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.image,
          flag: action.payload.flag,
          masterAgency: action.payload,
        },
      };
    case GET_MASTER_AGENCY_DETAILS:
      return {
        ...state,
        masterAgency: action.payload,
      };
    default:
      return state;
  }
};

export default masterAgencyReducer;
