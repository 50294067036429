import React, { useEffect, useState } from "react";

//routing
import { Link } from "react-router-dom";

//redux
import { connect, useSelector } from "react-redux";

//action
import HostTable from "../Agency/HostList";
import { getAllHosts } from '../../store/sub-admin/action';


const HostsSubAdmin = (props) => {
    useEffect(() => {
        props.getAllHosts();
    }, []);


    const { hosts } = useSelector((state) => state.subAdmin);

    return (
        <>
            <HostTable user={hosts?.hosts} />
        </>
    );
};

export default connect(null, { getAllHosts })(HostsSubAdmin);
