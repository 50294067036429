import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";

const AgencyAuthRouter = ({ component: Component, isAgency, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAgency === true ? <Redirect to="/agency" /> : <Component {...props} />
    }
  />
);

const mapStateToProps = (state) => ({
  isAgency: state.agency.isAgency,
});

export default connect(mapStateToProps)(AgencyAuthRouter);
